<template>
  <div>
    <el-form :model="form"
             ref="formRef"
             label-width="100px">
      <el-row :gutter="10"
              style="padding-bottom:10px">
        <el-col :span="6">
          <el-form-item label="起始时间">
            <el-date-picker v-model="form.begin_time"
                            type="datetime"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="截止时间">
            <el-date-picker v-model="form.end_time"
                            type="datetime"
                            style="width:100%"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button type="primary"
                     @click="search">提交</el-button>
        </el-col>
      </el-row>
    </el-form>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <Info ref="InfoRef" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import Info from './info.vue'
export default {
  data () {
    return {
      tableData: [],
      form: {},
      pageName: 'feedback_list_admin',
      tableColumns: [
        { prop: 'question_id', align: 'center', label: '题目ID', width: '' },
        { prop: 'subject_name', align: 'center', label: '科目', width: '' },
        { prop: 'category', align: 'center', label: '反馈类型', width: '', isStatus: true },
        { prop: 'question_error_time', align: 'center', label: '反馈时间', width: '' },
        { prop: 'comment', align: 'center', label: '反馈内容', width: '' },
        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'img', btns: [
            { content: '查看详情', type: 'look', event: 'showForm' },
          ]
        },
      ],
      page: 1,
      size: 10,
      totalElements: 0,
    }
  },
  created () {
  },
  components: {
    Info
  },
  mounted () {
    this.form = JSON.parse(this.$route.query.form)
    this.search()
    this.setTablesColums()
  },
  methods: {
    getClass (prop, value) {
    },
    getValue (prop, value) {
      if (prop == 'category') {
        return value ? '批改反馈' : '题目反馈'
      }
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      if (JSON.stringify(this.form) == '{}') {
        return
      }
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v2/stats/student_question_report',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    showForm (row) {
      let form = JSON.parse(JSON.stringify(row))
      this.$refs.InfoRef.form = form
      this.$refs.InfoRef.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>