<template>
  <div style="background:white;overflow:hidden">
    <el-dialog title="查看题目"
               v-if="dialogVisible"
               :visible.sync="dialogVisible"
               :close-on-click-modal="false"
               :append-to-body="true"
               width="800px"
               :before-close="handleClose">
      <div class="card">
        <div class="card_head">
          <span>题目编号：{{form.question_id}}</span> 题目分数:<span style="color:#FF6147">{{form.question_score}}</span>
        </div>
        <div class="box-card">
          <div class="clearfix">
            <span>题目内容：</span>
          </div>
          <div>
            <div v-if="form.question_head_content !== ''"
                 class="topic_content"
                 v-html="$replaceAll(form.question_head_content)">
            </div>
            <div class="topic_content"
                 v-html="$replaceAll(form.question_content)"></div>
          </div>
          <div class="bottom_border"></div>

        </div>
        <div class="box-card">
          <div class="clearfix">
            <span>题目答案：</span>
          </div>
          <div class="topic_content"
               style="color:#FF806C"
               v-html="$replaceAll(form.question_answer)">
          </div>
          <div class="bottom_border"></div>

        </div>
        <div class="box-card">
          <div class="clearfix">
            <span>题目解释：</span>
          </div>
          <div style=""
               class="topic_content"
               v-html="$replaceAll(form.question_explain)">
          </div>
          <div class="bottom_border"></div>

        </div>
      </div>
      <div class="comment"
           v-if="form.user_score">
        得分:{{form.user_score}}
      </div>
      <div class="comment"
           v-if="form.teacher_comment">
        评语:{{form.teacher_comment}}
      </div>
      <div class="comment">
        反馈内容:{{form.comment}}
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      form: [],
      id: '',
      dialogVisible: false
    }
  },

  mounted () {
    // document.getElementsByClassName('view_warp')[0].style.background = '#F6F6F6'
    // this.id = this.$route.query.id
    // this.getInfo()
  },
  methods: {
    getInfo () {
      this.$http({
        url: '/api/v1/favorites/favorite_show?question_id=' + this.id,
        method: 'get'
      }).then(res => {
        this.form = res.data.list
      })
    },
    handleClose () {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin: auto;
  font-size: 16px;
  padding-bottom: 20px;
  .card_head {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    background: #eff8ff;
    height: 60px;
    line-height: 60px;
    text-indent: 1em;
    span {
      color: #666666;
      margin-right: 20px;
    }
  }
}
.el-card {
  padding: 0 15px 15px 15px;
}
.box-card {
  margin-top: 20px;
  .clearfix {
    margin-bottom: 20px;
    border-left: 4px solid #37a0f4;
    text-indent: 1em;
    font-size: 16px;
    font-weight: bold;
  }
  .topic_content {
    margin-left: 20px;
    font-size: 16px;
    word-wrap: break-word;
  }
}
.bottom_border {
  width: 98%;
  height: 10px;
  border-bottom: 1px solid #e8e8e8;
  margin-left: 20px;
}

.comment {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

::v-deep .el-dialog__footer {
  text-align: center;
}
.el-input {
  width: 100px;
}
::v-deep .el-dialog__header {
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: center;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: white;
  background: #cccccc;
  border-radius: 50%;
  padding: 4px;
}
</style>